// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accommodations-tsx": () => import("./../../../src/pages/accommodations.tsx" /* webpackChunkName: "component---src-pages-accommodations-tsx" */),
  "component---src-pages-design-system-tsx": () => import("./../../../src/pages/design-system.tsx" /* webpackChunkName: "component---src-pages-design-system-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../../../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-registry-tsx": () => import("./../../../src/pages/registry.tsx" /* webpackChunkName: "component---src-pages-registry-tsx" */),
  "component---src-pages-rsvp-tsx": () => import("./../../../src/pages/rsvp.tsx" /* webpackChunkName: "component---src-pages-rsvp-tsx" */),
  "component---src-pages-wedding-party-tsx": () => import("./../../../src/pages/wedding-party.tsx" /* webpackChunkName: "component---src-pages-wedding-party-tsx" */),
  "component---src-pages-wedding-tsx": () => import("./../../../src/pages/wedding.tsx" /* webpackChunkName: "component---src-pages-wedding-tsx" */),
  "component---src-templates-accommodation-page-tsx": () => import("./../../../src/templates/AccommodationPage.tsx" /* webpackChunkName: "component---src-templates-accommodation-page-tsx" */)
}

